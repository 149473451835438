import React, { useContext } from 'react';
import { PaymentContext } from './PaymentPage';

function Support() {
  const paymentContext = useContext(PaymentContext);
  const { receiver } = paymentContext.paymentInfoDTO || {};

  if (!receiver?.businessName) {
    return <></>;
  }

  return (
    <div className="text-center w-96 my-10">
      {`If you have any questions, contact `}
      <span className="font-bold">{receiver?.businessName}</span>
      {` at `}
      <a
        href={`mailto:${receiver?.contactEmail}`}
        className="text-primary-500 font-bold"
      >
        {receiver?.contactEmail}
      </a>
      {` or call at `}
      <a
        href={`tel:${receiver?.contactPhone}`}
        className="text-primary-500 font-bold"
      >
        {receiver?.contactPhone}
      </a>
    </div>
  );
}

export default Support;
