import React, { ReactElement } from 'react';
import Spinner from './Spinner';
import clsx from 'clsx';

type Variants = 'outlined' | 'emphasized';
type Size = 'md' | 'lg';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  widthFull?: boolean;
  children: string;
  variant?: Variants;
  size?: Size;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function Button({
  loading,
  children,
  onClick,
  disabled,
  variant = 'outlined',
  size = 'md',
  widthFull = false,
  className
}: Props): ReactElement {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'flex items-center justify-center rounded-full font-bold focus:ring focus:ring-blue-200 outline-none',
        {
          'h-12 px-4 text-lg': size === 'lg',
          'h-10 px-4 text-base': size === 'md',
          'bg-primary-500 text-white ': variant === 'emphasized',
          'bg-white text-primary-500 border-solid border border-primary-500':
            variant === 'outlined',
          'opacity-70 pointer-events-none': disabled,
          'w-full': widthFull
        },
        className
      )}
    >
      {loading && <Spinner className="h-5 w-5 mr-2" />}
      {children}
    </button>
  );
}

export default Button;
