import React from 'react';

function Bank(): JSX.Element {
  return (
    <svg
      width="322"
      height="210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#bank_icon_a)">
        <path
          d="M282.96 123.94a21.188 21.188 0 0 0 4.89 3.166c0 .048.7 4.275-1.182 6.074-1.166 1.117-4.021 1.238-5.405.217-1.761-1.317-2.734-8.67 1.705-9.457h-.008zm-15.706.297c4.52-.096 3.281 7.883 1.946 9.313-.04.056-4.6 3.56-4.825-3.15-.209-6.018 1.882-6.115 2.887-6.163h-.008zm-.177 12.19c8.123-.522 7.841-9.988 4.753-12.736-1.512-1.35-10.375-4.669-9.772 7.135.266 5.159 2.823 5.705 5.019 5.625v-.024zm54.261-24.999c0-1.004 0-1.004-.273-1.229l-.121.08c-1.431-16.376-7.809-10.373-12.465-5.994-14.557-6.388-33.239 3.214-28.953 14.849a11 11 0 0 0 1.754 3.062c-7.239 1.776-6.161 10.711-2.268 12.857 1.777.996 12.98 3.214 13.004-6.155 2.517.393 4.962 1.173 7.487 1.542l-.056 4.018c-1.544-.136-3.12-.241-4.729-.337v2.041c4.274.208 8.533.662 12.755 1.358v-2.041a78.167 78.167 0 0 0-4.278-.627l.056-4.098c1.624.09 3.253.066 4.874-.073 5.589 7.682 10.881 14.938 12.457 5.995.298 1.687.33 1.993.378 2.507l.386-27.819-.008.064zm.201-14.054-.804 55.638c.048-3.391-3.748-5.903-11.388-7.537-17.934-3.809-51.149-1.189-51.471-1.157l.805-55.638c.329 0 33.544-2.652 51.47 1.165 7.641 1.607 11.439 4.117 11.396 7.53"
          fill="#BCBEC0"
        />
        <path
          d="M283.917 123.875c2.333.057 3.507 1.021 3.925 3.215a21.172 21.172 0 0 1-4.882-3.166 5.48 5.48 0 0 1 .957-.065v.016zm37.421-12.495-.386 27.819v-.353c-.072-.531-.104-.804-.346-2.154-1.093 6.219-4.471 5.368-10.02-2.523-.732-1.045-1.689-2.411-2.413-3.471-1.621.138-3.25.162-4.874.072l.065-4.821c1.198.675 2.79 1.566 3.956 2.274v-2.234c-3.506-2.065-3.506-2.065-5.484-3.078a90.145 90.145 0 0 0-2.212-.217l-.113 7.73a45.014 45.014 0 0 1-7.495-1.679c-.499-8.397-9.651-6.798-10.729-6.589-8.042-10.929 6.434-21.752 21.216-19.655 2.075.282 4.097.869 6 1.744 4.455-4.187 6.482-6.1 9.562-3.954 1.777 2.065 2.413 4.203 2.903 9.948l.233-.056c.118.39.173.797.161 1.205"
          fill="#D8D4D4"
        />
        <path
          d="M299.6 122.702a89.58 89.58 0 0 1 2.211.217c3.66 1.953 3.804 2.033 5.485 3.078v2.234a186.388 186.388 0 0 0-3.957-2.274l-.064 4.821-.056 4.098c1.48.177 2.911.386 4.278.627v2.041a111.846 111.846 0 0 0-12.755-1.358v-2.041c1.609.096 3.217.201 4.729.337l.056-4.018.113-7.73M287.85 127.106c-.844-4.452-5.839-3.721-7.133-1.857-1.191 1.704-2.51 9.82 4.069 8.775 3.981-.627 3.217-5.874 3.064-6.918zm-3.9-5.191c10.551.217 8.693 9.811 6.144 12.206-3.08 2.892-14.476 3.865-14.026-5.537.282-5.576 4.439-6.709 7.882-6.669zM267.109 134.458c4.415-.305 3.651-8.244 2.083-9.642-.056-.049-5.509-3.424-4.825 6.139.193 2.555.981 3.559 2.718 3.479l.024.024zm.145-12.206c7.994-.209 7.64 8.992 4.632 12.134-1.608 1.679-9.61 5.247-9.868-4.018-.193-6.999 2.694-8.035 5.236-8.14"
          fill="#6D6E71"
        />
        <path
          d="M270.012 96.137c-2.597.168-1.986 4.693-1.19 5.416.466.426 3.313 1.229 3.056-3.166-.088-1.607-.683-2.298-1.866-2.25zm9.37-.273a1.932 1.932 0 0 0-2.051 1.478c-.193.691-.443 2.989.539 3.849.506.434 4.021 1.607 3.723-3.021-.112-1.607-.804-2.323-2.211-2.306zm-.805 17.147c2.566-8.035 17.01-13.748 28.856-8.726 5.268-4.725 11.259-10.069 12.667 5.809l.241-.064c.117.394.174.803.169 1.214l-.386 27.537v-.16l-.056 4.017v2.29a9.91 9.91 0 0 0-.338-1.679v-2.017l.249.755c.056-4.13.056-4.13-.233-5.721-1.528 8.646-6.434 2.266-12.586-5.81-14.115 1.198-32.338-5.624-28.583-17.445zm-17.25-14.85v-1.253c.514-.434 1.182-1.02 1.721-1.43l.74-.048-.073 6.645 1.689-.096v1.149c-2.726.16-2.726.16-4.23.265v-1.149l1.287-.089.064-5.022-1.223 1.029h.025zm8.549 4.822c-4.601.113-3.587-5.625-2.413-6.95 1.447-1.608 6.048-1.825 6.305 2.33.169 2.78-1.287 4.508-3.884 4.62h-.008zm9.377-.273c-5.63 0-4.528-5.529-3.129-6.879 1.834-1.775 7.689-1.872 7.641 2.845 0 2.555-1.609 4.018-4.512 4.018v.016zm-20.339-13.861-.764 55.629c.121 0 4.463-.345 10.793-.61 33.319-1.366 50.94 1.703 50.972 8.839.257-18.455.512-36.913.764-55.373.048-3.337-3.665-5.804-11.139-7.4-17.54-3.73-50.288-1.15-50.666-1.118"
          fill="#BCBEC0"
        />
        <path
          d="M278.618 113.011c-3.732 11.845 14.476 18.643 28.582 17.445 6.176 8.076 11.058 14.464 12.586 5.81.161.868.201 1.109.29 1.672l.064-4.774c-.072.29-.161.683-.249.965v-2.21c.096-.394.233-.924.305-1.326v.378c-.032 2.555-.067 5.105-.104 7.65.048-2.66.048-2.66.394-27.377v-.804a1.695 1.695 0 0 0-.056-.273c0-.056-.105-.113-.137-.169-1.609-3.503.402-8.919-4.826-10.526-2.654 0-3.578.803-8.042 4.821-10.97-4.653-26.073-.089-28.856 8.726"
          fill="#D8D4D4"
        />
        <path
          d="m320.019 141.987.057-4.017.064-4.773c-.072.289-.161.683-.249.964v-2.21c.096-.394.233-.924.305-1.326v.378c-.032 2.555-.067 5.105-.104 7.65l-.056 4.017v2.291a9.902 9.902 0 0 0-.338-1.68v-2.017l.249.756"
          fill="#6D6E71"
        />
        <path
          d="m263.844 95.446-.74.048c-.523.426-1.231.972-1.721 1.43v1.254l1.222-1.029-.064 5.022-1.287.089v1.149c1.528-.105 2.373-.161 4.23-.265v-1.149l-1.688.096c0-1.663.04-3.89.064-5.56v-1.085M269.948 101.874c-.2.025-.404.008-.597-.05a1.46 1.46 0 0 1-.899-.753 1.482 1.482 0 0 1-.153-.579c-.112-.563-.37-3.021.571-3.937.25-.241 3.064-1.648 3.024 2.338 0 2.001-.619 2.917-1.946 2.981zm.072-6.862c-4.511.273-3.908 5.986-2.453 7.288 1.456 1.302 6.563 1.133 6.257-3.945-.136-2.226-1.52-3.44-3.804-3.343zM279.325 101.617a1.775 1.775 0 0 1-2.018-1.414c-.153-.53-.41-2.829.531-3.785.554-.562 3.715-1.559 3.763 2.097 0 2.122-.667 3.078-2.276 3.102zm.065-6.854c-5.63.168-4.625 5.737-3.273 7.007 1.608 1.543 7.857 1.872 7.704-3.319-.072-2.41-1.649-3.736-4.431-3.688z"
          fill="#D1D3D4"
        />
        <path
          d="M319.464 135.816a19.43 19.43 0 0 1 .37 3.616c-.064 4.934-.217 6.035-.49 6.348h-.065c-.41-.554-.265-8.373.185-9.964zm-2.822 3.552c.273-.249.989-.908.723 5.673-.217 5.528-1.319 4.516-1.327 4.5-.643-.683-.691-8.887.604-10.173zm-.177 12.053c2.179-2.322 2.083-14.367.764-14.327-2.349.072-3.901 12.383-1.81 14.464.29.289.652.241 1.046-.153v.016zm4.085-40.193c0 1.036-.064 1.454-.603 6.098-.37 3.126-1.222 10.447-.692 16.433.032.205.049.412.049.619-.137 1.607-.579 3.093-.676 4.668v.724c0 .458-.048 1.084-.064 1.55-.105 3.882.096 6.083.595 6.549.563-.337.804-1.679.925-8.79a.538.538 0 0 1 .12-.314l.386-27.537h-.04zm-.402 31.667-.04.547v2.017l.04-.547v-2.017zm.627-45.513c-.252 18.455-.506 36.913-.764 55.373-.354 2.965-2.123 5.898-9.795 11.346l.764-55.276c6.487-4.543 9.752-8.357 9.795-11.443z"
          fill="#BCBEC0"
        />
        <path
          d="M319.561 135.567c.04-.072.121-.217.177 0 .1.799.135 1.606.104 2.411-.241-1.069-.37-2.146-.378-2.162.026-.085.058-.169.097-.249zm.989-24.323-.386 27.537c-.233-1.8.708-3.68-.346-5.432-.161.113-.257.177-.514 1.021-.684-7.779 1.013-15.42 1.246-23.151"
          fill="#D8D4D4"
        />
        <path
          d="m320.116 142.911-.04.547-.033 2.017.041-.547.032-2.017zM319.842 137.978c0-2.242-.072-2.459-.185-2.523-.571.45-.892 9.923-.354 10.341.161-.08.531-.265.539-7.818zm-.249-4.356a.748.748 0 0 1 .161-.233h.096c.708 1.182 0 14.593-.731 14.464-.893-.12-.869-11.643.474-14.263M316.497 149.485c.531-.587.804-1.913.884-4.733.202-5.625-.345-5.561-.522-5.529-1.199.217-1.89 9.514-.804 10.358.112.088.265.048.418-.096h.024zm.153-12.054c.506-.474 1.689-1.607 1.415 6.493-.314 9.257-2.654 7.682-2.67 7.658-1.399-1.406-1.222-11.692 1.255-14.151z"
          fill="#6D6E71"
        />
        <path
          d="M318.58 110.713c-.571.683-.748 6.035-.193 5.906.699-.168.715-5.681.394-5.954 0 0-.089-.072-.201.048zm1.689-2.21c-.378.675-.531 5.85-.161 5.89.37.041.643-5.568.273-5.946 0 0-.048 0-.088.072l-.024-.016zm-.314 17.478c.249-4.886 1.23-9.699 1.383-14.593l-.386 27.819c-.587-1.093-1.383-5.625-.997-13.226zm-4.713-10.575v-1.27l.981-2.153.346-.306-.096 6.726.675-.627v1.158a36.432 36.432 0 0 1-2.035 1.799v-1.157l.765-.651.072-5.086-.732 1.607.024-.04zm3.193 2.306c-.756.804-.893-1.133-.909-2.338-.072-4.822 1.086-6.252 1.464-5.834.547.611.482 7.023-.555 8.172zm1.689-2.21c-.193.29-.346.177-.442-.337-.459-2.29.112-8.18.764-7.875.369.169.321 7.184-.322 8.212zm-8.469-6.565-.804 55.638c6.509-4.548 9.782-8.381 9.82-11.499l.804-55.637c-.048 3.112-3.324 6.945-9.828 11.498"
          fill="#BCBEC0"
        />
        <path
          d="M319.955 125.981c-.282 5.552.048 10.526.893 13.298.144-8.365.345-19.526.49-27.891v.297c-.362 4.774-1.134 9.507-1.375 14.296"
          fill="#D8D4D4"
        />
        <path
          d="m316.593 111.685-.345.306-.982 2.153v1.27l.732-1.607-.072 5.086-.764.651v1.157c.74-.61 1.423-1.213 2.035-1.8v-1.157l-.676.627.097-6.726M318.499 116.563c-.193.193-.418.177-.434-1.72-.04-4.21.603-4.29.732-4.138.265.33.321 5.167-.298 5.858zm.097-6.943c-1.335 1.567-1.311 8.285-.418 8.245.08 0 .804-.105 1.061-2.829.145-1.47.282-5.15-.241-5.56-.105-.081-.241 0-.402.144zM320.188 114.353c0 .048-.12.185-.193-.152-.153-.804-.128-5.625.362-5.746.282.177.137 5.408-.169 5.898zm.097-6.942c-.925 1.607-.805 8.348-.274 8.22.692-.161 1.15-8.036.483-8.373 0 0-.089-.04-.209.153z"
          fill="#D1D3D4"
        />
        <path
          d="M44.715 53.404 160.926 0l116.22 53.404H44.716zM277.146 53.404H44.716v10.342h232.43V53.404zM192.026 78.716h-62.199v93.614h62.199V78.716zM270.993 200.205H50.875V210h220.118v-9.795zM226.375 188.288H95.486v4.894h130.889v-4.894z"
          fill="#BCBEC0"
        />
        <path
          d="M91.264 73.87V59.255H62.706V73.87h4.882v111.717h-4.882v14.617h28.558v-14.617H86.39V73.871h4.874zM259.155 73.87V59.255H230.589V73.87h4.882v111.717h-4.882v14.617h28.566v-14.617h-4.882V73.871h4.882zM121.077 73.87V59.255H98.317V73.87h4.882v102.3h-4.882v14.616h22.76v-14.616h-4.874v-102.3h4.874zM223.109 73.87V59.255H200.35V73.87h4.873v102.3h-4.873v14.616h22.759v-14.616h-4.881v-102.3h4.881z"
          fill="#BCBEC0"
        />
        <path
          d="M177.485 147.877c-2.959 2.904-6.919 4.712-11.878 5.424v8.703h-8.042v-8.526a36.12 36.12 0 0 1-9.764-2.033 25.983 25.983 0 0 1-7.889-4.476l3.45-7.368a26.558 26.558 0 0 0 14.758 5.882v-16.288a56.271 56.271 0 0 1-8.847-2.941 15.371 15.371 0 0 1-6.04-4.781c-1.608-2.143-2.412-4.902-2.412-8.277a14.58 14.58 0 0 1 2.123-7.722 17.272 17.272 0 0 1 5.919-5.802 22.083 22.083 0 0 1 8.702-2.957v-8.702h8.042v8.63a32.336 32.336 0 0 1 8.557 2.194 22.623 22.623 0 0 1 6.748 4.154l-3.451 7.369a24.796 24.796 0 0 0-12.401-5.625v17.011a55.78 55.78 0 0 1 8.71 2.861 14.78 14.78 0 0 1 5.806 4.588c1.566 2.03 2.352 4.695 2.357 7.995a14.46 14.46 0 0 1-4.448 10.687zm-24.681-30.816a15.283 15.283 0 0 0 5.34 2.861v-15.131a9.842 9.842 0 0 0-5.3 2.748 7.138 7.138 0 0 0-1.922 5.095 5.62 5.62 0 0 0 1.882 4.427zm17.266 25.409a7.184 7.184 0 0 0 1.722-4.982 4.925 4.925 0 0 0-1.722-3.922 14.278 14.278 0 0 0-5.026-2.507v14.03a9.111 9.111 0 0 0 5.026-2.619z"
          fill="#E6E7E8"
        />
        <path
          d="M1.946 120.685c.981-.803.732 2.274.732 2.306-.137 1.495-.869 1.961-1.062 1.72-.193-.241-.515-3.27.33-4.018v-.008zM.169 123.2c0-.104 0-.104.072-.112.233.241.217 3.592 0 4.17a30.725 30.725 0 0 1-.048-4.058H.169zm.136 8.976.217 10.575v-1.985c0-1.35 0-1.35-.217-8.59zm7.745-14.15v-.86a16.537 16.537 0 0 0-1.753 0l-.684.369.097 4.565c-.475.265-.941.538-1.4.803v.804a37.195 37.195 0 0 1 3.909-2.122v-.803c-.426.201-.877.426-1.343.667l-.073-3.456h1.271l-.024.033zm-5.96 7.344c1.915-1.752 1.786-5.721.628-5.794-.362 0-1.214.402-1.56 1.865-.29 1.245-.258 3.736.153 4.154.168.177.41.112.804-.225H2.09zM.17 123.345l.104 4.749c.047-.119.08-.243.097-.37.707-2.949.321-5.89 0-5.624-.185.16-.17.932-.17 1.181l-.031.064zm-.065-4.982c0-1.31 1.174-2.804 3.595-4.42a43.804 43.804 0 0 1 7.31-3.712l.805 37.582a43.81 43.81 0 0 0-7.31 3.713C2.09 153.133.875 154.635.9 155.945l-.804-37.582"
          fill="#BCBEC0"
        />
        <path
          d="M.281 132.168c.065 1.495.21 2.981.242 4.476.048 2.033-.048 4.066 0 6.099l-.217-10.575"
          fill="#D8D4D4"
        />
        <path
          d="m5.565 117.503.684-.369c.584-.033 1.17-.033 1.753 0v.859h-1.27l.072 3.456c.466-.241.917-.466 1.343-.667v.803a37.195 37.195 0 0 0-3.909 2.122v-.804c.459-.289.925-.562 1.4-.803l-.097-4.565M2.027 124.639c.989-.916.804-4.074.233-4.098-.981-.04-1.11 3.592-.643 4.17.096.113.225.088.41-.072zm-.097-4.709c1.207-1.013 1.737.402 1.552 2.354-.209 2.29-1.817 3.882-2.252 3.295-.434-.587-.707-4.395.7-5.625M.257 127.146c.233-1.029.17-4.018 0-4.066-.225.241 0 4.13 0 4.162v-.096zm-.16-3.769a4.07 4.07 0 0 1 .056-.932c.088-.289.185-.345.281-.169a11.779 11.779 0 0 1-.225 5.866l-.105-4.749"
          fill="#D1D3D4"
        />
        <path
          d="M1.544 126.165c.12 1.53.007 3.069-.338 4.565v-.081c-.152-.49-.152-.49-.257-5.504v-.104c0-1.31.257-1.133.29-1.109.032.024.265.482.33 2.233h-.025zm4.222.442c-2.26 1.27-1.246-5.158-1.23-5.223.41-1.534 1.689-2.362 2.212-1.43.321.563 1.133 5.4-.982 6.653zm-.2-8.18c-3.475 2.178-2.944 9.49-1.36 9.908.804.217 2.911-.86 3.716-2.973.804-2.113.643-5.472-.057-6.621-.498-.804-1.27-.924-2.3-.314zm-3.218 6.638c-.136-2.66-.868-3.102-1.238-2.604-.18.341-.264.724-.241 1.109l.426 20.434c0-2.933.547-5.825.611-8.75 0-1.278-.515-2.483-.499-3.769a.682.682 0 0 1 .073-.201 15.686 15.686 0 0 0 .868-6.219zm9.104-14.561.805 37.582c-.137.049-10.77 4.211-10.689 7.939l-.804-37.582c-.072-3.736 10.552-7.891 10.688-7.939z"
          fill="#BCBEC0"
        />
        <path
          d="M1.206 130.689c0 .041 0 .041-.08.394a.71.71 0 0 1-.056.185v-1.262c.03.229.087.452.168.667l-.032.016zm.748 3.922c.161 3.15-.603 6.243-.595 9.377l-.233-11.249v-.065c.07-.424.186-.839.346-1.237.276 1.026.437 2.08.482 3.142"
          fill="#D8D4D4"
        />
        <path
          d="M1.544 126.165c-.088-2.635-.434-2.258-.466-2.201a2.754 2.754 0 0 0-.153 1.181l.129 6.083v.048c.177-.45.587-1.84.482-5.111h.008zm.796-1.1a17.272 17.272 0 0 1-.2 3.672c-.218 1.31-.805 2.531-1.062 3.817a2.286 2.286 0 0 0 0 .233L.9 123.57v-.193c.096-1.269.603-1.189.804-.956.346.37.571 1.278.643 2.644M5.613 119.745c-1.881 1.189-1.552 6.774-.57 7.031.361.097 1.487-.329 1.873-1.872.386-1.543.258-4.179-.185-4.95-.241-.434-.619-.506-1.118-.209zm.17 8.172c-4.102 2.339-2.413-6.187-2.413-6.276.804-3.166 3.498-4.515 4.487-2.9.603.988 1.609 6.95-2.107 9.176"
          fill="#6D6E71"
        />
        <path
          d="m.933 125.177.104 4.862c0-.177 0-.177-.104-4.822v-.04zm52.275 36.658c.852.466.394-2.989.305-3.302-.193-.651-.627-.804-.804-.475-.241.475-.346 3.287.499 3.777zm-4.094-1.607c1.786.53 1.062-2.724.805-3.214-.37-.643-1.609-1.27-1.923-.137 0 0-.579 2.789 1.134 3.343l-.016.008zM.877 123.658c.136 2.098.651 4.155.683 6.26.957.273 2.413.755 2.413.763l-.056-2.724a12.29 12.29 0 0 1-1.705-.932v-1.366a16.599 16.599 0 0 0 5.3 2.122v1.366c-1.054-.274-1.239-.322-2.06-.595l.065 2.748c.893.41 1.472.707 2.413 1.214 4.02-3.062 16.084-6.718 25.735 2.322 11.484-1.736 17.975 10.044 14.99 15.34-2.331 4.146-9.256 4.483-14.588 2.41-7.753 6.035-19.535 4.966-25.735-2.33-3.362.297-6.836-1.728-6.973-6.163L.901 123.57l-.024.088zm54.269 39.286v.852c-.06.093-.128.182-.201.265l-.129-.225-.096-4.508c-.105-.137-.242-.329-.354-.458v-.804c.363.369.612.835.716 1.342v.804c-.04-.145-.089-.346-.145-.49l.072 3.407a.938.938 0 0 0 .12-.185h.017zm-2.019-5.866c.378.241.805.715.965 2.338 0 .121.298 3.014-.402 3.215-.225.056-1.15-.258-1.608-1.857-.217-.803-.37-2.659.112-3.447a.615.615 0 0 1 .917-.217l.016-.032zm-4.101-1.575c2.863.98 2.879 4.821 1.608 5.448-.724.338-2.316-.128-3.136-1.157a4.152 4.152 0 0 1-.563-3.511c.217-.595.764-1.222 2.115-.804l-.024.024zm-48.23-37.06.804 37.582c0 .844.571 1.656 1.906 2.411 4.512 2.515 11.533 2.917 19.672 3.375 5.521.217 11.027.727 16.495 1.527 15.384 2.635 15.602 5.56 15.674 6.525l-.804-37.558c0-1.366-1.255-2.524-3.692-3.448-9.353-3.551-19.518-4.13-28.477-4.628-8.131-.466-15.16-.86-19.672-3.375-1.335-.747-1.89-1.567-1.906-2.411z"
          fill="#BCBEC0"
        />
        <path
          d="M1.037 129.998v1.222-1.374a.35.35 0 0 0 0 .152zm.057 2.781.233 11.249c.048.491.08 1.141.169 1.608 1.094 3.214 3.514 4.821 6.804 4.532 2.758 3.214 6.482 6.171 15.015 6.235 4.173-.337 6.972-1.036 10.696-3.897 10.455 3.793 18.135-1.326 14.476-9.603-2.212-4.965-8.042-9.361-14.822-8.148-9.65-9.096-21.714-5.407-25.735-2.33-.901-.506-1.48-.803-2.413-1.213l.064 3.214a51.92 51.92 0 0 0 1.882-.579v1.495c-.868.281-1.56.506-2.525.804l-.885-.362c0-1.728-.072-3.455-.112-5.183-.17-.064-.7-.249-2.22-.715-.402.932.684 2.571-.684 2.909"
          fill="#D8D4D4"
        />
        <path
          d="m4.11 135.776.884.362c.756-.217 1.36-.386 2.525-.804v-1.495c-1.085.346-1.174.37-1.881.579l-.065-3.214-.064-2.748c1.021.329 1.158.369 2.059.594v-1.366a17.205 17.205 0 0 1-5.3-2.121v1.366c.541.357 1.112.669 1.705.932l.056 2.724c0 1.728.072 3.455.113 5.183M.933 125.177c.04 1.607.129 3.142.137 4.709v1.374l-.13-6.083H.934zm.008-1.607.193 9.217v.064c.257-.129.507-1.334.458-3.303-.048-1.968-.603-4.017-.675-6.018"
          fill="#6D6E71"
        />
        <path
          d="m54.84 163.868.129.225a1.6 1.6 0 0 0 .201-.265v-.852a1.402 1.402 0 0 1-.12.185l-.073-3.407c.057.161.105.324.145.49v-.803a2.627 2.627 0 0 0-.716-1.342v.803c.105.137.257.314.354.458v1.881c-.082.875-.063 1.756.056 2.627M53.151 157.858c.226.144.467.45.547 1.607.049.683.121 2.29-.265 2.346 0 0-.555.064-.804-1.245-.08-.475-.193-2.234.12-2.66a.262.262 0 0 1 .173-.108.255.255 0 0 1 .197.052l.032.008zm.073 4.652c1.608.876.667-4.146.651-4.202-.378-1.375-1.31-1.688-1.729-.916-.514.956-.45 4.218 1.078 5.118z"
          fill="#D1D3D4"
        />
        <path
          d="M49.066 156.226a1.656 1.656 0 0 1 1.126 1.447s.362 2.097-.362 2.467a1.416 1.416 0 0 1-1.608-.756 4.478 4.478 0 0 1-.202-2.667c.153-.507.491-.667 1.03-.491h.016zm.08 4.653c3.113.948 2.26-2.989 1.762-3.889-.853-1.551-3.459-2.475-4.022-.619 0 0-.9 3.487 2.26 4.508z"
          fill="#D1D3D4"
        />
        <path
          d="M51.945 154.169a8.528 8.528 0 0 1-2.51 1.093c-.667 4.894 2.615 6.179 3.274 5.673.66-.506.893-5.456-.804-6.766h.04zm2.268 2.853.137 6.557v-.048c-.113-4.251-.113-4.251-.129-6.485l-.008-.024zm-.241 1.631c.064 1.728.257 6.983-4.97 1.84a6.545 6.545 0 0 1-1.898-4.701c-1.384-.642-2.88-.313-4.311-.53l.056 2.756c.925.201 1.842.418 2.75.659v1.382a71.896 71.896 0 0 0-7.824-1.607v-1.382a84.43 84.43 0 0 1 2.79.458l-.056-2.78a24.567 24.567 0 0 1-3.217-1.061c-8.75 5.697-21.931 4.476-28.767-2.668-7.882.547-7.938-2.041-8.284-18.891v-.048c.048-1.439.105-2.917 7.914.988l1.222-.804c7.102-3.599 16.246-3.061 21.248-.731 2.847 1.326 5.035 4.018 8.468 4.114a14.788 14.788 0 0 1 9.209 3.689c6.12 5.777 5.252 11.169 4.592 13.218a6.588 6.588 0 0 1-.33.804c.587.715 1.255 1.526 1.4 5.303l.008-.008zM.152 125.82v2.29l-.096-4.733v.056c0 .804.073 1.608.097 2.411v-.024zm53.546 6.509c0-1.19-1.021-2.306-3.482-3.214-9.176-3.488-19.205-4.058-28.052-4.557-8.307-.466-15.489-.867-20.186-3.487-1.327-.747-2.002-1.607-2.018-2.555l.804 37.582c0 .953.675 1.805 2.027 2.555 4.696 2.62 11.87 3.021 20.186 3.496 8.846.498 18.867 1.06 28.043 4.548 2.461.94 3.466 2.057 3.49 3.214l-.804-37.582"
          fill="#BCBEC0"
        />
        <path
          d="M51.51 153.912c-1.181-.498-1.857-.056-2.074 1.35a8.503 8.503 0 0 0 2.51-1.093 1.88 1.88 0 0 0-.435-.257zm1.61-6.428c-.99-6.284-7.858-12.64-16.126-11.724-4.302-3.857-7.961-5.432-13.985-6.043-6.86-.144-10.873.764-14.782 3.375-7.97-4.018-7.938-2.37-7.913-.94l.217 10.575c.112 2.651.386 8.839 8.042 8.316 1.68 1.728 6.788 6.991 18.32 6.22a22.546 22.546 0 0 0 5.63-1.141c1.689-.522 2.887-2.025 4.68-2.33 1.271-.217 2.212.683 3.338.98l-.072-3.271-2.566.716v-1.511c1.609-.466 1.962-.571 3.507-.972l1.343.273.104 5.239c1.424.24 2.868.334 4.31.281.21-5.223 4.553-3.053 5.47-2.193.965-1.961.66-4.749.482-5.85z"
          fill="#D8D4D4"
        />
        <path
          d="m42.72 150.023-1.342-.273c-1.697.442-1.914.506-3.507.972v1.511l2.566-.715.072 3.27.056 2.78a87.148 87.148 0 0 0-2.79-.458v1.382c2.636.39 5.248.927 7.825 1.607v-1.382a62.528 62.528 0 0 0-2.75-.659l-.057-2.756-.105-5.239M49.436 155.262c.402-2.636 2.51-1.262 2.96-.627.659.924 1.536 6.694-.202 6.389-2.412-.41-3.12-3.279-2.758-5.762zm2.05-2.684c-4.6-1.936-4.93 3.214-3.843 5.866 1.085 2.652 5.5 5.111 6.12 3.761 0-.065 1.48-7.963-2.276-9.643"
          fill="#6D6E71"
        />
        <path
          d="M54.253 157.022c0 2.186.089 4.371.137 6.557l-.137-6.557z"
          fill="#6D6E71"
        />
        <path
          d="M.193 125.812c0-.804-.072-1.607-.097-2.411l.105 4.749v-2.306"
          fill="#D1D3D4"
        />
        <path
          d="M53.682 158.557c.225-.338.354-.434.434 0 .298 1.607.346 7.955-.804 7.232-.53-.354-.925-5.199.346-7.184l.024-.048zm-2.026 5.094c-.065 3.6 1.02 4.299 1.81 3.64.215-.198.392-.435.522-.699.418-1.02.418-1.02.241-9.522v-.177c0-.064 0-.16-.04-.233h-.097a11.244 11.244 0 0 0-2.412 7.007l-.024-.016zm-7.375-23.287c4.35-2.137 9.514-5.271 9.458-8.035l.804 37.582c.056 2.748-5.107 5.882-9.458 8.035l-.804-37.59"
          fill="#BCBEC0"
        />
        <path
          d="M53.819 165.491c.684-1.141.555-7.232.137-7.232-.113 0-.86.804-1.046 2.877-.12 1.39-.104 4.114.378 4.564.161.145.346.073.531-.217v.008zm-2.147-1.896a11.412 11.412 0 0 1 1.978-6.38c.346-.531.402-.603.507-.643.072.145.072.145.088.442l.137 6.557c.064 5.11-1.97 3.648-1.986 3.632-.676-.715-.74-2.571-.724-3.608z"
          fill="#6D6E71"
        />
        <path
          d="m54.543 132.297.804 37.558c-.048.667-.096 1.301-1.27 2.667-.253-12.519-.507-25.041-.765-37.566a4.054 4.054 0 0 0 1.255-2.659"
          fill="#BCBEC0"
        />
        <path
          d="m27.5 0 2.95 12.166L40 16.26l-7.725 1.563L27.5 31.917l-4.775-14.094L15 16.26l9.55-4.094L27.5 0zM7.5 22.94l1.77 7.224 5.73 2.43-4.635.93L7.5 41.89l-2.865-8.368L0 32.595l5.73-2.431L7.5 22.94zM277.5 157l1.771 7.223 5.729 2.431-4.635.929-2.865 8.368-2.865-8.368-4.635-.929 5.729-2.431L277.5 157zM292 165.977l3.305 13.306L306 183.761l-8.652 1.71L292 200.886l-5.348-15.415-8.652-1.71 10.695-4.478L292 165.977z"
          fill="#C4C4C4"
        />
      </g>
      <defs>
        <clipPath id="bank_icon_a">
          <path fill="#fff" d="M0 0h321.531v210H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Bank;
