import React from 'react';
import AuditoriaLogo from '../../components/AuditoriaLogo';
import styles from './PaymentPage.module.css';

function Header() {
  return (
    <header
      className={`${styles.header} w-full bg-cover flex justify-center items-center`}
    >
      <div className={`max-w-screen-xl flex justify-end w-full px-4 lg:px-12`}>
        <AuditoriaLogo />
      </div>
    </header>
  );
}

export default Header;
