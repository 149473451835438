import axios from 'axios';
import React, { useContext, useState } from 'react';
import Button from '../../components/Button';
import { formatCurrency } from '../../utils/format';
import { ACHPaymentPayload } from './ACH';
import { PaymentContext } from './PaymentPage';
import Support from './Support';

type Props = {
  onSuccess: () => void;
  onError: () => void;
  achPaymentPayload?: ACHPaymentPayload;
  paymentLinkToken?: string;
};

interface Field {
  label: string;
  value?: string;
}

const FieldRow = ({ field }: { field: Field }) => {
  return (
    <div className="flex justify-between leading-6">
      <div className="mr-10 w-40 text-right">{field.label}</div>
      <div className="font-bold w-40">{field.value}</div>
    </div>
  );
};
function ACHAuth({
  onSuccess,
  onError,
  achPaymentPayload,
  paymentLinkToken
}: Props) {
  const paymentContext = useContext(PaymentContext);
  const { payment, receiver, payer } = paymentContext.paymentInfoDTO || {};

  const [accepted, setAccepted] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const fields1: Field[] = [
    {
      label: 'Transaction Date',
      value: new Date().toDateString()
    },
    { label: 'Invoice Number', value: payment?.invoice?.number },
    {
      label: 'Total Amount',
      value: formatCurrency(
        payment?.totalAmount || 0,
        payment?.invoice?.currency
      )
    },
    { label: 'Country', value: achPaymentPayload?.achAuthConfig?.country },
    { label: 'State', value: achPaymentPayload?.achAuthConfig?.state },
    { label: 'IP Address', value: achPaymentPayload?.achAuthConfig?.ipAddress }
  ];

  const fields2: Field[] = [
    {
      label: 'Payer Name',
      value: payer?.businessName
    },
    {
      label: 'Billing Contact Name',
      value: achPaymentPayload?.billingInformation?.name
    },
    {
      label: 'Billing Contact Email',
      value: achPaymentPayload?.billingInformation?.email
    }
  ];

  const handleConfirmClick = async () => {
    setPaymentInProgress(true);
    try {
      await axios.post('/api/data-listener/payments/achPayment', {
        accountId: achPaymentPayload?.accountId,
        link: paymentLinkToken,
        publicToken: achPaymentPayload?.publicToken,
        billingInformation: achPaymentPayload?.billingInformation
      });
      setPaymentInProgress(false);
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
    }
  };

  return (
    <div className="flex flex-col items-center max-w-2xl self-start mt-9">
      <h1 className="font-bold text-2xl mb-7">{`ACH Payment Authorization`}</h1>
      <div className="flex items-start text-lg">
        <input
          checked={accepted}
          className="block mr-3 w-7 h-7"
          id="accept"
          type="checkbox"
          onChange={(event) => {
            setAccepted(event.target.checked);
          }}
        />
        <label
          className="block font-bold"
          htmlFor="accept"
        >{`I authorize ${receiver?.businessName} to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.`}</label>
      </div>
      <div className="my-10">
        {fields1.map((field, index) => (
          <FieldRow key={`f1-${index}`} field={field} />
        ))}
      </div>
      <div>
        {fields2.map((field, index) => (
          <FieldRow key={`f2-${index}`} field={field} />
        ))}
      </div>

      <Support />
      <div className="flex justify-center w-full">
        <div className="mr-5 flex-1">
          <Button
            widthFull
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="ml-5 flex-1">
          <Button
            widthFull
            size="lg"
            variant="emphasized"
            disabled={!accepted || paymentInProgress}
            loading={paymentInProgress}
            onClick={handleConfirmClick}
          >
            Confirm Transaction
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ACHAuth;
