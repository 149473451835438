import axios from 'axios';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Bank from '../../components/icons/Bank';
import CreditCard from '../../components/icons/CreditCard';
import Spinner from '../../components/Spinner';
import { formatCurrency } from '../../utils/format';
import { PaymentContext } from './PaymentPage';

interface ReceiptResponse {
  receiptLink: string;
}
interface Props {
  paymentLinkToken: string;
  type: string;
}

function PaymentSuccess({ paymentLinkToken, type }: Props): ReactElement {
  const paymentContext = useContext(PaymentContext);
  const { payment, receiver, payer } = paymentContext?.paymentInfoDTO || {};
  let { totalAmount } = payment || {};

  if (paymentContext?.updatedPayment?.totalAmount) {
    totalAmount = paymentContext?.updatedPayment?.totalAmount;
  }

  const { currency } = payment?.invoice || {};

  const [receiptURL, setReceiptURL] = useState<string>();
  const [receiptError, setReceiptError] = useState<string>();

  useEffect(() => {
    const fetchReceiptURL = async () => {
      try {
        const receiptResponse = await axios.post<ReceiptResponse>(
          `/api/data-listener/payments/receipt/${encodeURIComponent(
            paymentLinkToken || ''
          )}`,
          {}
        );
        setReceiptURL(receiptResponse?.data?.receiptLink);
      } catch (error) {
        setReceiptError('Unable to fetch receipt.');
      }
    };
    paymentLinkToken && fetchReceiptURL();
  }, [paymentLinkToken]);

  const receiptSection = () => {
    if (receiptURL) {
      return (
        <p>
          You can{' '}
          <a download href={receiptURL} className="text-primary-500 font-bold">
            download a copy of receipt here
          </a>
          .
        </p>
      );
    }
    if (receiptError) {
      return <></>;
    }
    return <Spinner className="text-primary-500 h-8 w-8 mt-9" />;
  };

  return (
    <div className="text-2xl flex flex-col items-center leading-10 text-center">
      {type === 'ACH' ? <Bank /> : <CreditCard />}
      <p className="text-3xl font-bold mt-12">Thanks for the payment!</p>
      <p className="mt-16">
        Your payment of{' '}
        <span className="font-bold">
          {formatCurrency(totalAmount || 0, currency)}
        </span>{' '}
        to <span className="font-bold">{receiver?.businessName}</span> is being
        processed.
      </p>
      <p>
        We have emailed a receipt to{' '}
        <span className="font-bold">{payer?.email}</span>
      </p>
      {receiptSection()}
    </div>
  );
}

export default PaymentSuccess;
