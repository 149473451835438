import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import NotFound from './public/payment/NotFound';
import PaymentPage from './public/payment/PaymentPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/payment/:paymentMethod/:paymentId"
          element={<PaymentPage />}
        />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
