import React, { ReactElement, useContext } from 'react';
import { PaymentContext } from '../../public/payment/PaymentPage';
import { formatCurrency } from '../../utils/format';

type PaymentStatusType = 'PAID' | 'UNPAID' | 'DISABLED' | 'NO_APP';

export interface PaymentDTO {
  totalAmount: number;
  fee: number;
  delegateFeeToPayer: boolean;
  invoice: {
    number: string;
    amount: number;
    dueDate: string;
    currency: string;
  };
}
export interface PaymentInfoDTO {
  payment: PaymentDTO;

  payer: {
    businessName: string;
    email: string;
  };
  receiver: {
    businessName: string;
    contactEmail: string;
    contactPhone: string;
  };
  stripeAccount: string;
  clientSecret: string;
  stripePublishableKey: string;
  paymentStatus: PaymentStatusType;
}

function PaymentInfo(): ReactElement {
  const paymentContext = useContext(PaymentContext);
  const { receiver, payer, payment } = paymentContext?.paymentInfoDTO || {};

  const {
    number: invoiceNumber,
    dueDate,
    amount: invoiceAmount,
    currency
  } = payment?.invoice || {};

  const { totalAmount, fee, delegateFeeToPayer } = payment || {};

  return (
    <div className="flex flex-col">
      <div className="text-2xl">
        <span className="text-gray-600">Paying </span>
        <span className="font-bold ">{receiver?.businessName}</span>
      </div>
      <div className="text-5xl font-bold mt-3 whitespace-nowrap">
        {formatCurrency(totalAmount || 0, currency)}
      </div>
      {delegateFeeToPayer ? (
        <>
          <div className="text-2xl mt-16 flex justify-between items-end">
            <div className="mr-2">Invoice Number</div>
            <div className="font-bold">
              <div className="font-bold break-all">{invoiceNumber}</div>
            </div>
          </div>
          <div className="text-lg flex justify-between mt-6 font-bold">
            <div className="mr-2">Invoice Amount</div>
            <div>{formatCurrency(invoiceAmount || 0, currency)}</div>
          </div>

          <div className="text-lg mt-2 flex justify-between border border-solid border-border-neutral border-r-0 border-l-0 py-2">
            <div className="mr-2">Payment Fee</div>
            <div>{formatCurrency(fee || 0, currency)}</div>
          </div>

          <div className="text-lg flex justify-between mt-2 mb-6 font-bold">
            <div className="mr-2">Total</div>
            <div>{formatCurrency(totalAmount || 0, currency)}</div>
          </div>
        </>
      ) : (
        <div className="text-2xl mt-16">
          Invoice Number: <span className="font-bold ">{invoiceNumber}</span>
        </div>
      )}
      <div className="text-2xl mt-3">{`Due by ${dueDate}`}</div>
      <div className="text-2xl mt-16">
        <span className="text-gray-600">By </span>{' '}
        <span className="font-bold ">{payer?.businessName}</span>
      </div>
    </div>
  );
}

export default PaymentInfo;
