import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import NotFound from './NotFound';
import PaymentInfo, {
  PaymentInfoDTO
} from '../../components/payment/PaymentInfo';
import Spinner from '../../components/Spinner';
import styles from './PaymentPage.module.css';
import PaymentSuccess from './PaymentSuccess';
import { GENERIC_ERROR_MESSAGE } from '../../utils/labels';
import { ACHPaymentPayload } from './ACH';
import ACHAuth from './ACHAuth';
import Header from './Header';
import Footer from './Footer';
import Support from './Support';

const CC = React.lazy(() => import('./CC'));
const ACH = React.lazy(() => import('./ACH'));

const supportedPaymentTypes = ['ach', 'cc'];

type PageStatus =
  | 'LOADING'
  | 'PAYMENT_PAGE'
  | 'PAYMENT_IN_PROGRESS'
  | 'ERROR'
  | 'ACH_AUTH'
  | 'SUCCESS';

export interface UpdatedPayment {
  totalAmount: number;
  fee: number;
  invoiceAmount: number;
  currency: string;
  previousFee: number;
}
interface PaymentInfoContext {
  paymentInfoDTO?: PaymentInfoDTO;
  paymentLinkToken?: string;
  updatedPayment?: UpdatedPayment;
  setUpdatedPayment?: (updatedPayment?: UpdatedPayment) => void;
}
export const PaymentContext = React.createContext<PaymentInfoContext>({});

function PaymentPage() {
  const { paymentMethod, paymentId: paymentLinkToken } = useParams();

  const [pageStatus, setPageStatus] = useState<PageStatus>('LOADING');

  const [paymentInfoDTO, setPaymentInfoDTO] = useState<PaymentInfoDTO>();
  const [achPayload, setAchPayload] = useState<ACHPaymentPayload>();

  const [errorMessage, setErrorMessage] = useState('');

  const [updatedPayment, setUpdatedPayment] = useState<UpdatedPayment>();

  const handlePaymentStatus = (paymentDTO: PaymentInfoDTO) => {
    const status = paymentDTO.paymentStatus;
    switch (status) {
      case 'UNPAID':
        setPageStatus('PAYMENT_PAGE');
        break;
      case 'PAID':
        setErrorMessage('This payment has been made.');
        setPageStatus('ERROR');
        break;
      case 'DISABLED':
      case 'NO_APP':
        setErrorMessage('Payment has been disabled.');
        setPageStatus('ERROR');
        break;
      default:
        setErrorMessage(GENERIC_ERROR_MESSAGE);
        setPageStatus('ERROR');
        break;
    }
  };

  useEffect(() => {
    paymentLinkToken &&
      paymentMethod &&
      axios
        .post<PaymentInfoDTO>(
          `/api/data-listener/payments/validate/${encodeURIComponent(
            paymentMethod
          )}/${encodeURIComponent(paymentLinkToken)}`
        )
        .then((response) => {
          setPaymentInfoDTO(response.data);
          handlePaymentStatus(response.data);
        })
        .catch((error) => {
          const errorMessageFromResponse = error?.response?.data?.message;
          setErrorMessage(errorMessageFromResponse || GENERIC_ERROR_MESSAGE);
          setPageStatus('ERROR');
        });
  }, [paymentMethod, paymentLinkToken]);

  if (
    !paymentMethod ||
    !supportedPaymentTypes.includes(paymentMethod.toLocaleLowerCase())
  ) {
    return <NotFound />;
  }

  const spinnerJSX = <Spinner className="text-primary-500 h-8 w-8 mt-9" />;

  const successPage =
    paymentInfoDTO && paymentLinkToken ? (
      <PaymentSuccess
        paymentLinkToken={paymentLinkToken}
        type={paymentMethod.toUpperCase()}
      />
    ) : (
      <></>
    );

  const errorPage = (
    <div className="flex flex-col">
      <p className="text-lg text-center">{errorMessage}</p>
      <Support />
    </div>
  );
  const paymentPage = paymentInfoDTO ? (
    <div className="flex justify-center items-center flex-1 flex-col md:flex-row">
      <div className={`${styles.maxContainer} flex flex-col flex-1 p-9 mr-7`}>
        <PaymentInfo />
      </div>

      <div className={`${styles.maxContainer} flex flex-col flex-1 p-9 ml-7`}>
        <div className="flex flex-col">
          {paymentMethod.toLowerCase() === 'cc' && (
            <CC
              amount={paymentInfoDTO.payment.totalAmount}
              clientSecret={paymentInfoDTO.clientSecret}
              stripePublishableKey={paymentInfoDTO.stripePublishableKey}
              onSuccess={() => {
                setPageStatus('SUCCESS');
              }}
            />
          )}

          {paymentLinkToken && paymentMethod.toLocaleLowerCase() === 'ach' && (
            <ACH
              paymentLinkToken={paymentLinkToken}
              onSuccess={(achPaymentPayload) => {
                setPageStatus('ACH_AUTH');
                setAchPayload(achPaymentPayload);
              }}
              onError={(error) => {
                setErrorMessage(error);
                setPageStatus('ERROR');
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  const getMain = () => {
    switch (pageStatus) {
      case 'LOADING':
        return spinnerJSX;
      case 'SUCCESS':
        return successPage;
      case 'ERROR':
        return errorPage;
      case 'PAYMENT_PAGE':
        return paymentPage;
      case 'ACH_AUTH':
        return (
          <ACHAuth
            paymentLinkToken={paymentLinkToken}
            achPaymentPayload={achPayload}
            onError={() => {
              setErrorMessage(GENERIC_ERROR_MESSAGE);
              setPageStatus('ERROR');
            }}
            onSuccess={() => {
              setPageStatus('SUCCESS');
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <PaymentContext.Provider
        value={{
          paymentInfoDTO,
          paymentLinkToken,
          updatedPayment,
          setUpdatedPayment
        }}
      >
        <Header />
        <main
          className={`${styles.main} max-w-screen-xl flex justify-center items-center m-auto shadow-2xl py-9 px-20`}
        >
          <Suspense fallback={spinnerJSX}>{getMain()}</Suspense>
        </main>
        <Footer />
      </PaymentContext.Provider>
    </>
  );
}

export default PaymentPage;
