import React from 'react';

function CardCard(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="375"
      height="196"
      fill="none"
    >
      <rect
        width="283.991"
        height="164.578"
        rx="12"
        transform="rotate(-6.468 307.214 -334.102) skewX(.033)"
        fill="#E1E1E1"
      />
      <path
        fill="#C4C4C4"
        d="m42.98 62.211 282.183-31.99 3.727 32.703L46.706 94.916zM280.848 127.095c1.341 11.767-7.136 22.391-18.934 23.729-11.798 1.337-22.45-7.118-23.791-18.885-1.341-11.768 7.136-22.392 18.935-23.729 11.798-1.338 22.449 7.117 23.79 18.885z"
      />
      <path
        d="M311.65 123.603c1.341 11.768-7.136 22.391-18.935 23.729-11.798 1.337-22.449-7.118-23.79-18.885-1.341-11.768 7.136-22.392 18.934-23.729 11.798-1.338 22.45 7.117 23.791 18.885zM27.5 0l2.95 12.166L40 16.26l-7.725 1.563L27.5 31.917l-4.775-14.094L15 16.26l9.55-4.094L27.5 0zM346.5 106.723l1.771 7.223 5.729 2.431-4.635.928-2.865 8.369-2.865-8.369-4.635-.928 5.729-2.431 1.771-7.223zM7.5 22.94l1.77 7.224 5.73 2.43-4.635.93L7.5 41.89l-2.865-8.368L0 32.595l5.73-2.431L7.5 22.94zM361 115.699l3.305 13.307L375 133.483l-8.652 1.711L361 150.609l-5.348-15.415-8.652-1.711 10.695-4.477L361 115.699z"
        fill="#C4C4C4"
      />
      <rect
        x="8"
        y="161.58"
        width="120"
        height="1.995"
        rx=".997"
        fill="#C4C4C4"
      />
      <rect
        x="68"
        y="168.562"
        width="75"
        height="1.995"
        rx=".997"
        fill="#C4C4C4"
      />
      <rect
        x="151"
        y="168.562"
        width="12"
        height="1.995"
        rx=".997"
        fill="#C4C4C4"
      />
    </svg>
  );
}

export default CardCard;
