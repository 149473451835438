import React from 'react';
import Footer from './Footer';
import Header from './Header';
import styles from './PaymentPage.module.css';
function NotFound() {
  return (
    <>
      <Header />
      <main
        className={`${styles.main} max-w-screen-xl flex justify-center items-center m-auto shadow-2xl py-9 px-20`}
      >
        Page not found. Please verify the URL.
      </main>
      <Footer />
    </>
  );
}

export default NotFound;
