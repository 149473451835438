import React from 'react';
import styles from './PaymentPage.module.css';

function Footer() {
  return (
    <footer
      className={`${styles.footer} flex justify-start items-center px-4 lg:px-12`}
      style={{ backgroundColor: '#322f81' }}
    >
      <div
        className={`max-w-7xl flex justify-start w-full px-12 m-auto text-white`}
      >
        Powered by Auditoria® SmartPay
      </div>
    </footer>
  );
}

export default Footer;
